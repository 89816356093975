import { NotificationSettingsRoutes } from '@maple-tech/company-settings-web-app/NotificationSettings';
import { NotificationImportant } from '@mui/icons-material';
import Page from 'Core/components/page';
import { COMPANIES, COUNTRIES } from 'Core/API';

export const notificationRoutes = (authorizationLevel, countryId) => ({
  path: 'company-settings/notification-settings',
  element: NotificationSettingsRoutes.element,
  order: 98,
  menuItem: true,
  requiredRoles: [
    'admin',
    (authorizationLevel.HUB_MANAGER ||
      (authorizationLevel.COUNTRY_MANAGER &&
        (countryId === COUNTRIES.SEPHORA.TURKIYE ||
          countryId === COUNTRIES.SEPHORA.ITALY))) &&
      COMPANIES.SEPHORA,
    COMPANIES.WORKMAPLE,
    COMPANIES.VINEYARD_VALLEY,
    COMPANIES.CENTRAL_RETAIL,
  ],
  title: 'Notifications Settings',
  permission: 'ADMIN',
  section: false,
  icon: <NotificationImportant />,
  children: NotificationSettingsRoutes?.children?.map((route) => ({
    path: route.path,
    element: <Page title="Notifications Settings">{route.element}</Page>,
    index: route.index,
    requiredRoles: [
      'admin',
      (authorizationLevel.HUB_MANAGER ||
        (authorizationLevel.COUNTRY_MANAGER &&
          (countryId === COUNTRIES.SEPHORA.TURKIYE ||
            countryId === COUNTRIES.SEPHORA.ITALY))) &&
        COMPANIES.SEPHORA,
      COMPANIES.WORKMAPLE,
      COMPANIES.VINEYARD_VALLEY,
      COMPANIES.CENTRAL_RETAIL,
    ],
  })),
});
