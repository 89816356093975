import { useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppstoreFilled,
  AreaChartOutlined,
  BookOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  FieldTimeOutlined,
  FileTextOutlined,
  ReconciliationFilled,
  SettingOutlined,
  ShopFilled,
  UnderlineOutlined,
  CarryOutOutlined,
} from '@ant-design/icons';
import { useUserController } from '@maple-tech/auth';
import NotFound from 'Core/routes/not-found';
import { lazy, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import KnowledgeBaseRoutes from './MdlKnowledgeBase';
import SurveyPagesRoutes from './Survey';
import DictionaryPagesRoutes from './Dictionary';
import FloorManagementRoutes from './FloorManagement';
import TasksRoutes from './Tasks';
import VMRoutes from './VisualMerchandising';
import ChecklistsFormsRoutes, {
  subRoutesForOldRoutes,
} from './ChecklistsForms';
import OrderRoutes from './order';
// import Trainings from "./trainings";
import { COMPANIES, COUNTRIES } from 'Core/API';
import Page from '../../core/components/page';
import { notificationRoutes } from './Notifications';
// import KnowledgeBasePagesRoutes from "@maple-tech/knowledge-base-web-app";
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { newCompanySettingsRoutes } from './NewCompanySettings';
import MapleAIAsistant from 'Core/components/MapleAI';
import {
  AutoAwesome,
  AutoAwesomeMosaicRounded,
  SmartToyRounded,
} from '@mui/icons-material';
import AnimeteurPagesRoutes from './Animeteur';
/**
 * Defines the router configuration.
 * Note that the title's are i18next namespaced keys.
 */
const SupportPage = lazy(() => import('./support'));
const SettingsPage = lazy(() => import('./Settings'));
const DashboardPage = lazy(() => import('./Dashboard'));
const MapleNotes = lazy(() => import('./MapleNotes'));
const MapleNotesDetailPage = lazy(() => import('./MapleNotes/Page'));
const TrainingManagerPage = lazy(() => import('./trainings/Page/Preview'));
const TrainingAdminPage = lazy(() => import('./trainings/Page/Admin'));
const CoachingPage = lazy(() => import('./Coaching'));

// // Dictionary
// const DictionaryPage = lazy(() => import("./Dictionary"));
// const DictionaryManagePage = lazy(() => import("./Dictionary/Manage"));

// // Storage
// const StoragePage = lazy(() => import("./Storage"));
// const StorageManagePage = lazy(() => import("./Storage/Manage"));

// Task
const MyTaskPage = lazy(() => import('./task/my'));
const AllTaskPage = lazy(() => import('./task/all'));
const CustomTaskPreviewPage = lazy(() => import('./task/CustomTaskPreview'));
const TaskTemplatePage = lazy(() => import('./task/templates'));
const CreateTaskTemplatePage = lazy(
  () => import('./task/templates/create-task'),
);

// Checklist
const ChecklistReportsPage = lazy(() => import('./checklist/Reports'));
const ChecklistReportsDashboardPage = lazy(
  () => import('./checklist/ReportsDashboard'),
);
const ChecklistDashboardPage = lazy(() => import('./checklist/Dashboard'));
const NotificationsPager = lazy(
  () => import('@maple-tech/company-settings-web-app/Notifications'),
);

const ChecklistUsageDashboardPage = lazy(
  () => import('./checklist/UsageDashboard'),
);
// const ChecklistUsageDashboardExportPage = lazy(() =>
//   import("./checklist/UsageDashboard/Export")
// );
const ChecklistAnswerSheetFormPage = lazy(
  () => import('./checklist/ListsWrapper/AnswerSheet/Form'),
);
const ChecklistAnswerSheetEditFormPage = lazy(
  () => import('./checklist/ListsWrapper/AnswerSheet/Edit'),
);
const ChecklistAnswerSheetPreviewPage = lazy(
  () => import('./checklist/ListsWrapper/AnswerSheet/Preview'),
);
const ChecklistAllistPage = lazy(() => import('./checklist/ListsWrapper'));
const ChecklistTemplateListPage = lazy(
  () => import('./checklist/template/ListTable'),
);
const ChecklistTemplateEditPage = lazy(
  () => import('./checklist/template/edit'),
);

const ChecklistTemplateTagsPage = lazy(
  () => import('./checklist/template/ListTable/TagManagement/EditConsumeTag'),
);

const CheckistCreateTemplatePage = lazy(
  () => import('./checklist/template/createChecklist'),
);

const MyVisitPage = lazy(() => import('./Visit/Pages/MyVisits'));
const AllVisitPage = lazy(() => import('./Visit/Pages/AllVisits'));
const VisitDetailPage = lazy(() => import('./Visit/Pages/VisitDetail'));
const StoreListPage = lazy(() => import('./Store/Pages/List'));
const StoresStreamPage = lazy(() => import('./Store/Pages/Stream/StreamPage'));
const StoreStreamPage = lazy(() => import('./Store/Pages/Stream'));
const StoreFloorManagementPage = lazy(
  () => import('./Store/Pages/FloorManagement'),
);
const StoreShiftPage = lazy(() => import('./Store/Pages/Shift'));
const StoreTargetSchedulePage = lazy(
  () => import('./Store/Pages/TagetSchedule'),
);
const AnalyticsPage = lazy(() => import('./Analytics'));
const ChecklistAnalyticsPage = lazy(() => import('./Analytics/Checklist'));
const AnalitycsChecklistFeedbackPage = lazy(
  () => import('./Analytics/Checklist/FeedBack'),
);
const DailyChecklistFeedbackPage = lazy(
  () => import('./Analytics/Checklist/FeedBack/Daily'),
);
const WeeklyChecklistFeedbackPage = lazy(
  () => import('./Analytics/Checklist/FeedBack/Weekly'),
);
const MonthlyChecklistFeedbackPage = lazy(
  () => import('./Analytics/Checklist/FeedBack/Monthly'),
);
const CreateCompanyPage = lazy(() => import('./CompanySettings/CreateCompany'));
const PermissionsPage = lazy(() => import('./CompanySettings/Permissions'));
const CreateCoachingCompetencePage = lazy(
  () => import('./CompanySettings/Coaching/CreateCompetenceItems'),
);
const HubManagementPage = lazy(() => import('./CompanySettings/HUB'));
const CountryManagementPage = lazy(
  () => import('./CompanySettings/CreateCountry'),
);
const RegionManagementPage = lazy(
  () => import('./CompanySettings/CreateRegion'),
);
const DistrictManagementPage = lazy(
  () => import('./CompanySettings/CreateDistrict'),
);
const LocationManagementPage = lazy(
  () => import('./CompanySettings/CreateLocation'),
);
const StoreManagementPage = lazy(
  () => import('./CompanySettings/CreateBuilding'),
);
const DepartmentManagementPage = lazy(
  () => import('./CompanySettings/CreateDepartment'),
);
const UserManagementPage = lazy(() => import('./CompanySettings/CreateUser'));
const PortalSettingsPage = lazy(() => import('./CompanySettings/Portal'));
const UserProfilePage = lazy(() => import('@maple-tech/auth-web-app/Profile'));
const DistrictManagerMonthlyReportsPage = lazy(
  () =>
    import(
      './Analytics/Checklist/DistrictAndStoreManager/DistrictManager/Monthly'
    ),
);
// const DistrictManagerWeeklyReportsPage = lazy(() =>
//   import("./Analytics/Checklist/DistrictAndStoreManager/DistrictManager/Weekly")
// );
// const DistrictManagerDailyReportsPage = lazy(() =>
//   import("./Analytics/Checklist/DistrictAndStoreManager/DistrictManager/Daily")
// );
// const DistrictManagerReportsPage = lazy(() =>
//   import("./Analytics/Checklist/DistrictAndStoreManager/DistrictManager")
// );
// const StoreManagerDailyReportsPage = lazy(() =>
//   import("./Analytics/Checklist/DistrictAndStoreManager/StoreManager/Daily")
// );
// const StoreManagerWeeklyReportsPage = lazy(() =>
//   import("./Analytics/Checklist/DistrictAndStoreManager/StoreManager/Weekly")
// );
// const StoreManagerMonthlyReportsPage = lazy(() =>
//   import("./Analytics/Checklist/DistrictAndStoreManager/StoreManager/Monthly")
// );
// const StoreManagerReportsPage = lazy(() =>
//   import("./Analytics/Checklist/DistrictAndStoreManager/StoreManager")
// );
// const StoreManagerReports = lazy(() =>
//   import("./Analytics/Checklist/DistrictAndStoreManager")
// );

// userLevel lookup
// 0: Store Manager
// 1: Store Manager
// 2: Store Manager
// 3: District Manager
// 4: District Manager
// 5: Country Manager
// 6: Hub Manager
// 7: Global Manager

const useRetailRoutes = (isManager) => {
  const { t, i18n } = useTranslation(['core', 'retail']);
  const { user } = useUserController();

  // veriables to verify user level is equal or
  // greater than the required level
  const authorizationLevel = {
    STORE_MANAGER: user.userLevel >= 0,
    DISTRICT_MANAGER: user.userLevel >= 3,
    COUNTRY_MANAGER: user.userLevel >= 5,
    HUB_MANAGER: user.userLevel >= 6,
    GLOBAL_MANAGER: user.userLevel >= 7,
    DISTRICT_MANAGER_ONLY: user.userLevel === 3 || user.userLevel === 4,
    STORE_MANAGER_ONLY: user.userLevel >= 0 && user.userLevel <= 2,
    COUNTRY_MANAGER_ONLY: user.userLevel === 5,
    HUB_MANAGER_ONLY: user.userLevel === 6,
    GLOBAL_MANAGER_ONLY: user.userLevel === 7,
  };

  const checkMapleAdmin = Boolean(
    localStorage.getItem('grayGandalfAdmin') === 'true',
  );
  const showArchived = Boolean(localStorage.getItem('showArchived') === 'true');

  const routes = [
    {
      menuItem: false,
      requiredRoles: ['user'],
      path: '',
      element: (
        <Landing />
        // <Page title="Profile">
        //   <UserProfilePage />
        // </Page>
      ),
    },
    {
      // localStorage.getItem('mapleAiIsEnable') === 'true' && {
      menuItem: true,
      path: 'maple-ai',
      order: 1,
      requiredRoles: [
        'admin',
        // COMPANIES.LOREM,
        // COMPANIES.WORKMAPLE,
        COMPANIES.VINEYARD_VALLEY,
      ],
      icon: <AutoAwesome />,
      title: 'Maple AI',
      element: <MapleAIAsistant />,
    },
    {
      menuItem: false,
      requiredRoles: ['user'],
      path: 'support',
      title: 'Support',
      element: (
        <Page title="Support">
          <SupportPage />
        </Page>
      ),
    },
    {
      menuItem: true,
      archived: true,
      requiredRoles: ['admin'],
      path: 'notifications',
      icon: <NotificationsActiveTwoToneIcon />,
      title: 'Notifications',
      element: (
        <Page title="Notifications">
          <NotificationsPager previewMode="page" />
        </Page>
      ),
    },
    {
      menuItem: false,
      requiredRoles: ['admin'],
      path: 'settings',
      icon: <SettingOutlined />,
      element: (
        <Page title="Profile">
          <UserProfilePage />
        </Page>
      ),
    },
    {
      menuItem: true,
      archived: true,
      requiredRoles: ['admin'],
      path: 'dashboard',
      rootPath: '/dashboard/preview',
      icon: <DashboardOutlined />,
      title: 'retail:navigation.dashboard.section-title',
      element: <DashboardPage />,
    },

    {
      menuItem: true,
      archived: true,
      requiredRoles: ['admin'],
      path: 'notes',
      rootPath: '/notes',
      icon: <BookOutlined />,
      title: 'retail:navigation.maple-notes.section-title',
      element: <MapleNotes />,
      section: false,
      children: [
        {
          menuItem: false,
          requiredRoles: ['admin'],
          path: 'notes/:noteType',
          element: <MapleNotesDetailPage />,
        },
      ],
    },
    {
      menuItem: true,
      archived: true,
      requiredRoles: ['admin'],
      path: 'trainings',
      rootPath: '/trainings/preview',
      icon: <AppstoreFilled />,
      title: 'retail:navigation.trainings.section-title',
      section: true,
      element: <Outlet />,
      children: [
        {
          menuItem: true,
          requiredRoles: ['admin'],
          path: 'manager',
          icon: <AppstoreFilled />,
          element: <TrainingManagerPage />,
          title: 'retail:navigation.trainings.section-title',
        },
        {
          menuItem: true,
          requiredRoles: ['admin'],
          path: 'preview',
          icon: <AppstoreFilled />,
          title: 'retail:navigation.trainings.planning',
          element: <TrainingAdminPage />,
        },
      ],
    },
    DictionaryPagesRoutes,
    FloorManagementRoutes,
    // AnimeteurPagesRoutes, /* animeteurs now is a menu item under floor management named External Employees */
    SurveyPagesRoutes(authorizationLevel, user?.countryId),
    TasksRoutes,
    VMRoutes(user),
    //Coaching
    {
      menuItem: true,
      archived: true,
      requiredRoles: ['admin'],
      path: 'coaching',
      icon: <FileTextOutlined />,
      title: 'retail:navigation.coaching.section-title',
      element: <CoachingPage />,
    },
    // // Dictionary
    // {
    //   menuItem: true,
    //   requiredRoles: ["admin"],
    //   path: "dictionary",
    //   icon: <BookOutlined />,
    //   title: "retail:navigation.knowledge.dictionary",
    //   element: <DictionaryPage />,
    // },
    // {
    //   menuItem: false,
    //   requiredRoles: ["admin"],
    //   path: "dictionary/manage",
    //   title: "retail:navigation.knowledge.dictionary",
    //   element: <DictionaryManagePage />,
    // },
    // // Storage
    // {
    //   menuItem: true,
    //   requiredRoles: ["admin"],
    //   path: "storage",
    //   icon: <DatabaseOutlined />,
    //   title: "retail:navigation.knowledge.storage",
    //   element: <StoragePage />,
    // },
    // {
    //   menuItem: false,
    //   requiredRoles: ["admin"],
    //   path: "storage/manage",
    //   title: "retail:navigation.knowledge.storage",
    //   element: <StorageManagePage />,
    // },
    //Knowledge Base
    OrderRoutes,
    KnowledgeBaseRoutes(authorizationLevel, user.countryId),
    // ChecklistsFormsRoutes,
    // KnowledgeBasePagesRoutes(({ children }) => (
    //   <Page title="Knowledge Base">{children}</Page>
    // )),

    // {
    //   menuItem: true,
    //   requiredRoles: [COMPANIES.MEDIAMARKT, "admin"],
    //   path: "KnowledgeBase",
    //   rootPath: "/KnowledgeBase",
    //   icon: <PicLeftOutlined />,
    //   title: "retail:navigation.knowledge.section-title",
    //   section: true,
    //   element: <Outlet />,
    //   children: [
    //     {
    //       menuItem: true,
    //       requiredRoles: [COMPANIES.MEDIAMARKT, "admin"],
    //       path: "",
    //       index: true,
    //       title: "retail:navigation.knowledge.section-title",
    //       element: <KnowledgeBasePage />,
    //       // component: lazy(() => import("./KnowledgeBase")),
    //     },
    //     {
    //       menuItem: true,
    //       requiredRoles: [COMPANIES.MEDIAMARKT, "admin"],
    //       path: "articles",
    //       title: "retail:navigation.knowledge.articles",
    //       element: <KnowledgeBaseArticlesPage />,
    //     },
    //     {
    //       menuItem: false,
    //       requiredRoles: [COMPANIES.MEDIAMARKT, "admin"],
    //       path: "articles/:id",
    //       title: "retail:navigation.knowledge.articles",
    //       element: <KnowledgeBaseArticleDetailPage />,
    //     },
    //     {
    //       menuItem: true,
    //       requiredRoles: [COMPANIES.MEDIAMARKT, "admin"],
    //       path: "admin",
    //       title: "retail:navigation.knowledge.admin",
    //       element: <KnowledgeBaseAdminPage />,
    //     },
    //     {
    //       menuItem: false,
    //       requiredRoles: [COMPANIES.MEDIAMARKT, "admin"],
    //       path: "articles/manage",
    //       title: "retail:navigation.knowledge.manage",
    //       element: <KnowledgeBaseArticlesManagePage />,
    //     },
    //     {
    //       menuItem: false,
    //       requiredRoles: [COMPANIES.MEDIAMARKT, "admin"],
    //       path: "articles/manage/create",
    //       title: "retail:navigation.knowledge.manage",
    //       element: <KnowledgeBaseCreateArticlePage />,
    //     },
    //     {
    //       menuItem: false,
    //       requiredRoles: [COMPANIES.MEDIAMARKT, "admin"],
    //       path: "articles/manage/edit/:id",
    //       title: "retail:navigation.knowledge.manage",
    //       element: <KnowledgeBaseEditArticlePage />,
    //     },
    //   ],
    // },
    //Tasks
    {
      menuItem: true,
      archived: true,
      requiredRoles: ['admin'],
      path: 'task',
      icon: <FileTextOutlined />,
      title: 'retail:navigation.tasks.section-title',
      section: true,
      rootPath: '/task/my',
      element: <Outlet />,
      children: [
        {
          menuItem: true,
          requiredRoles: ['admin'],
          path: 'my',
          title: 'retail:navigation.tasks.my',
          // element: MyTaskRoute,
          element: <MyTaskPage />,
        },
        // {
        //  isComingSoon:true, path: "new-concept",
        //   title: "New Concept",
        //   // element: MyTaskRoute,
        //   element: lazy(() => import("./task/all/NewConcept")),
        // },
        {
          menuItem: true,
          requiredRoles: ['admin'],
          path: 'all',
          title: 'retail:navigation.tasks.all',
          element: <AllTaskPage />,
        },
        {
          menuItem: true,
          requiredRoles: ['admin'],
          path: 'custom',
          title: 'retail:navigation.tasks.custom',
          element: <CustomTaskPreviewPage />,
        },
        // {
        //  isComingSoon:true, path: "claim",
        //   title: "retail:navigation.tasks.claim",
        //   element: lazy(() => import("./task/Claim")),
        // },
        {
          menuItem: true,
          requiredRoles: ['admin'],
          path: 'template',
          title: 'retail:navigation.tasks.templates',
          element: <TaskTemplatePage />,
          children: [
            {
              menuItem: false,
              requiredRoles: ['admin'],
              path: 'create-task',
              element: <CreateTaskTemplatePage />,
            },
          ],
        },
      ],
    },

    //Checklists
    {
      //
      menuItem: true,
      requiredRoles: [
        COMPANIES.SEPHORA,
        COMPANIES.SEPHORA_FRANCE,
        COMPANIES.MEDIAMARKT,
        COMPANIES.LOREM,
        COMPANIES.WORKMAPLE,
        COMPANIES.DEMO,
        COMPANIES.VINEYARD_VALLEY,
        COMPANIES.CENTRAL_RETAIL,
        'admin',
      ],
      path: 'checklist',
      order: 2,
      icon: <UnderlineOutlined />,
      title: 'retail:navigation.checklists.section-title',
      section: true,
      rootPath: '/checklist/list/all-checklist',
      element: <Outlet />,
      children: [
        // {
        //   //
        //   path: "my",
        //   title: "retail:navigation.checklists.my",
        //   element: lazy(() =>
        //     import("./checklist/ListsWrapper/MyChecklist/")
        //   ),
        // },
        /////////////////////////////////

        {
          menuItem: true,
          requiredRoles: [
            COMPANIES.SEPHORA,
            COMPANIES.SEPHORA_FRANCE,
            COMPANIES.LOREM,
            COMPANIES.WORKMAPLE,
            COMPANIES.DEMO,
            COMPANIES.VINEYARD_VALLEY,
            COMPANIES.CENTRAL_RETAIL,
            COUNTRIES.MM_TURKIYE !== user?.countryId && COMPANIES.MEDIAMARKT,
            'admin',
          ].filter(Boolean),
          path: 'dashboard',
          title: 'retail:navigation.checklists.checklist-dashboard',
          element: <ChecklistDashboardPage />,
        },
        {
          menuItem: true,
          requiredRoles: [
            COMPANIES.SEPHORA,
            COMPANIES.SEPHORA_FRANCE,
            (COUNTRIES.MM_TURKIYE !== user?.countryId ||
              (COUNTRIES.MM_TURKIYE === user?.countryId &&
                authorizationLevel.COUNTRY_MANAGER)) &&
              COMPANIES.MEDIAMARKT,
            COMPANIES.LOREM,
            COMPANIES.WORKMAPLE,
            COMPANIES.DEMO,
            COMPANIES.VINEYARD_VALLEY,
            COMPANIES.CENTRAL_RETAIL,
            'admin',
          ].filter(Boolean),
          path: 'usageDashboard',
          title: 'retail:navigation.checklists.usage-dashboard',
          element: <ChecklistUsageDashboardPage />,
        },
        {
          menuItem: true,
          requiredRoles: [
            authorizationLevel.COUNTRY_MANAGER &&
              COUNTRIES.MM_TURKIYE === user?.countryId &&
              COMPANIES.MEDIAMARKT,
            'admin',
          ].filter(Boolean),
          path: 'reports-dashboard',
          title: 'retail:navigation.checklists.reports-dashboard',
          element: <ChecklistReportsDashboardPage />,
        },
        {
          menuItem: true,
          requiredRoles: [
            authorizationLevel.COUNTRY_MANAGER &&
              COUNTRIES.MM_TURKIYE === user?.countryId &&
              COMPANIES.MEDIAMARKT,
            'admin',
          ].filter(Boolean),
          path: 'reports',
          title: 'retail:navigation.checklists.reports',
          element: <ChecklistReportsPage />,
        },
        {
          menuItem: false,
          requiredRoles: ['admin'],
          path: 'list/:id/preview/:buildingId',
          title: null,
          element: <ChecklistAnswerSheetFormPage />,
        },
        {
          menuItem: false,
          requiredRoles: ['admin'],
          path: 'list/:id/edit/:buildingId',
          title: null,
          element: <ChecklistAnswerSheetEditFormPage />,
        },
        {
          menuItem: false,
          requiredRoles: ['admin'],
          path: 'list/:id/completed-preview/:buildingId',
          title: null,
          element: <ChecklistAnswerSheetPreviewPage />,
        },
        // {
        //   menuItem: true,
        //   requiredRoles: ['admin'],
        //   rootPath: '/checklist/list/all',
        //   path: 'list/:listType',
        //   title: 'retail:navigation.checklists.all',
        //   element: <ChecklistAllistPage />,
        // },
        ...subRoutesForOldRoutes(authorizationLevel, user.countryId),

        {
          menuItem: true,
          requiredRoles: [
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.SEPHORA_FRANCE,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.SEPHORA,
            COMPANIES.LOREM,
            COMPANIES.WORKMAPLE,
            COMPANIES.DEMO,
            COMPANIES.VINEYARD_VALLEY,
            COMPANIES.CENTRAL_RETAIL,
            authorizationLevel.COUNTRY_MANAGER &&
              COUNTRIES.MM_TURKIYE !== user?.countryId &&
              COMPANIES.MEDIAMARKT,
            'admin',
          ].filter(Boolean),
          path: 'templates',
          title: 'retail:navigation.checklists.templates',
          element: <ChecklistTemplateListPage />,
        },
        {
          menuItem: false,
          requiredRoles: [
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.SEPHORA_FRANCE,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.SEPHORA,
            COMPANIES.LOREM,
            COMPANIES.WORKMAPLE,
            COMPANIES.DEMO,
            COMPANIES.VINEYARD_VALLEY,
            COMPANIES.CENTRAL_RETAIL,
            authorizationLevel.COUNTRY_MANAGER &&
              COUNTRIES.MM_TURKIYE !== user?.countryId &&
              COMPANIES.MEDIAMARKT,
            'admin',
          ].filter(Boolean),
          path: 'templates/edit/:id',
          element: <ChecklistTemplateEditPage />,
        },
        {
          menuItem: false,
          requiredRoles: [
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.SEPHORA_FRANCE,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.SEPHORA,
            COMPANIES.LOREM,
            COMPANIES.WORKMAPLE,
            COMPANIES.DEMO,
            COMPANIES.VINEYARD_VALLEY,
            COMPANIES.CENTRAL_RETAIL,
            authorizationLevel.COUNTRY_MANAGER &&
              COUNTRIES.MM_TURKIYE !== user?.countryId &&
              COMPANIES.MEDIAMARKT,
            'admin',
          ].filter(Boolean),
          path: 'tags/edit/:id',
          element: <ChecklistTemplateTagsPage />,
        },
        {
          menuItem: false,
          requiredRoles: [
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.SEPHORA_FRANCE,
            authorizationLevel.COUNTRY_MANAGER && COMPANIES.SEPHORA,
            COMPANIES.LOREM,
            COMPANIES.WORKMAPLE,
            COMPANIES.DEMO,
            COMPANIES.VINEYARD_VALLEY,
            COMPANIES.CENTRAL_RETAIL,
            authorizationLevel.COUNTRY_MANAGER &&
              COUNTRIES.MM_TURKIYE !== user?.countryId &&
              COMPANIES.MEDIAMARKT,
            'admin',
          ].filter(Boolean),
          path: 'template/create',
          title: 'retail:navigation.checklists.templates',
          element: <CheckistCreateTemplatePage />,
        },
      ],
    },

    //CMS
    // {
    //  isComingSoon:true, path: "cms",
    //   icon: <ReconciliationFilled />,
    //   title: "Portal",
    //   element: lazy(() => import("./Cms/Page")),
    // },
    // //CMS
    // {
    //  isComingSoon:true, path: "cms",
    //   icon: <ReconciliationFilled />,
    //   title: "retail:navigation.cms.section-title",
    //   section: true,
    //   rootPath: "/cms/news",
    //   children: [
    //     {
    //      isComingSoon:true, path: "cms/news",
    //       title: "retail:navigation.cms.news",
    //       element: lazy(() => import("./Cms/Page")),
    //     },
    //     {
    //      isComingSoon:true, path: "cms/announcements",
    //       title: "retail:navigation.cms.announcements",
    //       element: lazy(() => import("./Cms/Page")),
    //     },
    //   ],
    // },
    //Visits
    {
      menuItem: true,
      archived: true,
      requiredRoles: ['admin'],
      path: 'visit',
      icon: <ReconciliationFilled />,
      title: 'retail:navigation.visits.section-title',
      section: true,
      rootPath: '/visit/storybook',
      children: [
        {
          menuItem: true,
          requiredRoles: ['admin'],
          path: 'visit/my',
          title: 'retail:navigation.visits.my',
          element: <MyVisitPage />,
        },
        {
          menuItem: true,
          requiredRoles: ['admin'],
          path: 'visit/all',
          title: 'retail:navigation.visits.all',
          element: <AllVisitPage />,
        },

        // {
        //  isComingSoon:true, path: "visit/live",
        //   title: "retail:navigation.visits.live",
        //   element: lazy(() => import("./Visit/Pages/LiveOverview")),
        // },
        {
          menuItem: false,
          requiredRoles: ['admin'],
          path: 'visit/detail/:id',
          title: 'retail:navigation.visits.detail',
          element: <VisitDetailPage />,
        },
        // {isComingSoon:true, path: "visit/history", title: "retail:navigation.visits.history" },
        // {isComingSoon:true, path: "visit/schedule", title: "retail:navigation.visits.scheduled" },
      ],
    },

    //Stores
    {
      menuItem: true,
      archived: true,
      requiredRoles: ['admin'],
      path: 'store',
      rootPath: '/store/list',
      icon: <ShopFilled />,
      title: 'retail:navigation.stores.home',
      section: true,
      children: [
        {
          menuItem: true,
          requiredRoles: ['admin'],
          path: 'list',
          icon: <ShopFilled />,
          title: 'retail:navigation.stores.list',
          element: <StoreListPage />,
        },
        {
          menuItem: true,
          requiredRoles: ['admin'],
          path: 'stream',
          icon: <FieldTimeOutlined />,
          title: 'retail:navigation.stores.stream',
          element: <StoresStreamPage />,
        },
        {
          menuItem: false,
          requiredRoles: ['admin'],
          path: ':id/stream',
          element: <StoreStreamPage />,
        },
        {
          menuItem: false,
          requiredRoles: ['admin'],
          path: ':id/floor-management',
          element: <StoreFloorManagementPage />,
        },
        {
          menuItem: false,
          requiredRoles: ['admin'],
          path: ':id/shift',
          element: <StoreShiftPage />,
        },
        {
          menuItem: false,
          requiredRoles: ['admin'],
          path: ':id/target-schedule',
          element: <StoreTargetSchedulePage />,
        },
      ],
    },

    //Analytics
    {
      menuItem: true,
      archived: true,
      requiredRoles: ['admin'],
      path: 'analytics',
      rootPath: '/analytics',
      icon: <AreaChartOutlined />,
      title: 'retail:navigation.analytics',
      section: true,
      children: [
        {
          menuItem: true,
          requiredRoles: ['admin'],
          path: 'analytics/stores',
          title: 'retail:navigation.analytics',
          element: <AnalyticsPage />,
        },
        {
          menuItem: true,
          requiredRoles: ['admin'],
          path: 'analytics/checklist',
          title: 'Checklist',
          element: <Outlet />,
          children: [
            {
              menuItem: true,
              requiredRoles: ['admin'],
              path: '',
              title: 'Checklist',
              element: <ChecklistAnalyticsPage />,
            },

            // {
            //   path: "report",
            //   title: "Raporlar",
            //   element: <Outlet />,
            //   children: [
            //     {
            //       path: "stores",
            //       title: "Mağaza Raporları",
            //       menuHidden: true,
            //       element: lazy(() =>
            //         import(
            //           "./Analytics/Checklist/DistrictAndStoreReports/Store"
            //         )
            //       ),
            //     },
            //     {
            //       path: "stores/questions-and-answers-in-stores",
            //       title: "Mağazalarda Verilen Cevap Raporları",
            //       menuHidden: true,
            //       element: lazy(() =>
            //         import(
            //           "./Analytics/Checklist/DistrictAndStoreManager/StoreManager/Daily/AnswersInTheStore"
            //         )
            //       ),
            //     },
            //     {
            //       path: "districts",
            //       title: "Mağaza Raporları",
            //       menuHidden: true,
            //       element: lazy(() =>
            //         import(
            //           "./Analytics/Checklist/DistrictAndStoreReports/District"
            //         )
            //       ),
            //     },
            //     {
            //       path: "other",
            //       title: "Mağaza Raporları",
            //       menuHidden: true,
            //       element: lazy(() => import("./Analytics/Checklist/Others")),
            //       children: [
            //         {
            //           path: "templates",
            //           title: "Mağaza Raporları",
            //           menuHidden: true,
            //           element: lazy(() =>
            //             import(
            //               "./Analytics/Checklist/Others/Templates/TemplateList"
            //             )
            //           ),
            //         },
            //         {
            //           path: "district-manager-monthly-report",
            //           title: "Bölge Müdürleri Aylık Liste Raporları",
            //           menuHidden: true,
            //           element: lazy(() =>
            //             import("./Analytics/Checklist/Others/DistrictManagers")
            //           ),
            //         },
            //       ],
            //     },
            //     {
            //       path: "store-manager",
            //       title: "Günlük/Haftalık Yoklama",
            //       menuHidden: true,
            //       element: StoreManagerReports,
            //       children: [
            //         {
            //           path: ":storeId",
            //           title: "Mağaza Raporları",
            //           menuHidden: true,
            //           element: StoreManagerReportsPage,
            //           children: [
            //             {
            //               path: "daily",
            //               title: "Günlük Rapor",
            //               menuHidden: true,
            //               element: StoreManagerDailyReportsPage,
            //             },
            //             {
            //               path: "weekly",
            //               title: "Haftalık Rapor",
            //               menuHidden: true,
            //               element: StoreManagerWeeklyReportsPage,
            //             },
            //             {
            //               path: "monthly",
            //               title: "Aylık Rapor",
            //               menuHidden: true,
            //               element: StoreManagerMonthlyReportsPage,
            //             },
            //           ],
            //         },
            //       ],
            //     },
            //     {
            //       path: "district-manager/:districtId",
            //       title: "Günlük Rapor",
            //       menuHidden: true,
            //       element: DistrictManagerReportsPage,
            //       children: [
            //         {
            //           path: "daily",
            //           title: "Günlük Rapor",
            //           menuHidden: true,
            //           element: DistrictManagerDailyReportsPage,
            //         },
            //         {
            //           path: "weekly",
            //           title: "Haftalık Rapor",
            //           menuHidden: true,
            //           element: DistrictManagerWeeklyReportsPage,
            //         },
            //         {
            //           path: "monthly",
            //           title: "Aylık Rapor",
            //           menuHidden: true,
            //           element: DistrictManagerMonthlyReportsPage,
            //         },
            //       ],
            //     },
            //   ],
            // },

            {
              menuItem: false,
              requiredRoles: ['admin'],
              path: 'feedback/:templateId',
              title: 'Günlük/Haftalık/Aylık Yoklama',
              element: <AnalitycsChecklistFeedbackPage />,
              children: [
                {
                  menuItem: false,
                  requiredRoles: ['admin'],
                  path: 'daily',
                  title: 'Günlük Yoklama',
                  element: <DailyChecklistFeedbackPage />,
                },
                {
                  menuItem: false,
                  requiredRoles: ['admin'],
                  path: 'weekly',
                  title: 'Haftalık Yoklama',
                  element: <WeeklyChecklistFeedbackPage />,
                },
                {
                  menuItem: false,
                  requiredRoles: ['admin'],
                  path: 'monthly',
                  title: 'Aylık Yoklama',
                  element: <MonthlyChecklistFeedbackPage />,
                },
              ],
            },
          ],
        },
      ],
    },
    notificationRoutes(authorizationLevel, user?.countryId),
    //Company Settings
    // {
    //   menuItem: true,
    //   requiredRoles: ['admin'],
    //   path: 'company-settings',
    //   icon: <SettingOutlined />,
    //   title: 'retail:navigation.companySettings.home',
    //   permission: 'ADMIN',
    //   section: true,
    //   children: [
    //     // {
    //     //   menuItem: true,
    //     //   requiredRoles: ['admin'],
    //     //   path: 'create-company',
    //     //   title: 'retail:navigation.companySettings.company',
    //     //   permission: 'MAPLE_ADMIN',
    //     //   element: <CreateCompanyPage />,
    //     // },
    //     {
    //       menuItem: true,
    //       requiredRoles: ['admin'],
    //       path: 'permissions',
    //       title: 'retail:navigation.companySettings.permission',
    //       element: <PermissionsPage />,
    //     },
    //     {
    //       menuItem: true,
    //       requiredRoles: ['admin'],
    //       path: 'coaching/competence-items',
    //       title: 'retail:navigation.companySettings.coaching.competenceItems',
    //       element: <CreateCoachingCompetencePage />,
    //     },
    //     {
    //       menuItem: true,
    //       requiredRoles: ['admin'],
    //       path: 'hubs',
    //       title: 'retail:navigation.companySettings.hub',
    //       element: <HubManagementPage />,
    //     },
    //     {
    //       menuItem: true,
    //       requiredRoles: ['admin'],
    //       path: 'countries',
    //       title: 'retail:navigation.companySettings.country',
    //       element: <CountryManagementPage />,
    //     },
    //     {
    //       menuItem: true,
    //       requiredRoles: ['admin'],
    //       path: 'regions',
    //       title: 'retail:navigation.companySettings.region',
    //       element: <RegionManagementPage />,
    //     },
    //     {
    //       menuItem: true,
    //       requiredRoles: ['admin'],
    //       path: 'districts',
    //       title: 'retail:navigation.companySettings.district',
    //       element: <DistrictManagementPage />,
    //     },
    //     {
    //       menuItem: true,
    //       requiredRoles: ['admin'],
    //       path: 'locations',
    //       title: 'retail:navigation.companySettings.location',
    //       element: <LocationManagementPage />,
    //     },
    //     {
    //       menuItem: true,
    //       requiredRoles: ['admin'],
    //       path: 'stores',
    //       title: 'retail:navigation.companySettings.store',
    //       element: <StoreManagementPage />,
    //     },
    //     {
    //       menuItem: true,
    //       requiredRoles: ['admin'],
    //       path: 'departments',
    //       title: 'retail:navigation.companySettings.department',
    //       element: <DepartmentManagementPage />,
    //     },
    //     {
    //       menuItem: true,
    //       requiredRoles: ['admin'],
    //       path: 'users',
    //       title: 'retail:settings.users.section-title',
    //       element: <UserManagementPage />,
    //     },
    //     // {
    //     //   path: "managed-employee",
    //     //   title: "retail:navigation.companySettings.managedEmployee",
    //     //   element: lazy(() =>
    //     //     import("./CompanySettings/CreateManagedEmployee")
    //     //   ),
    //     // },
    //     {
    //       menuItem: true,
    //       requiredRoles: ['admin'],
    //       path: 'portal',
    //       title: 'Portal Ayarları',
    //       element: <PortalSettingsPage />,
    //     },
    //   ],
    // },
    newCompanySettingsRoutes,
    {
      menuItem: false,
      requiredRoles: ['user'],
      path: '*',
      element: <NotFound />,
    },
  ].filter((route) => (showArchived ? true : route?.archived !== true));
  // user.permissions array
  const roles = useMemo(() => {
    const rolesArray = ['user'];
    if (user?.companyId) rolesArray.push(user.companyId);
    if (checkMapleAdmin) rolesArray.push('admin');
    return rolesArray;
  }, [user, checkMapleAdmin]);

  const authorizedRoutes = useMemo(
    () => filterRoutesFromRoles(routes, roles, user.userLevel),
    [roles, user],
  );

  const sidebar = useMemo(
    // generates menu and sorts menu elemets according to order property of route items.
    // settings items (company-notification settings) always at last of the menu
    () => generateSidebar(routes, roles, t, '', user.userLevel),
    [roles, i18n.language, user],
  );

  return { routes: authorizedRoutes, navigation: sidebar };
};

export default useRetailRoutes;

function filterRoutesFromRoles(routes, roles, userLevel) {
  return routes.map((route) => {
    let userLevelAccessQuery =
      !Array.isArray(route.userAccessLevel) ||
      route.userAccessLevel.findIndex((c) => c === userLevel) > -1;
    if (
      userLevelAccessQuery &&
      Array.isArray(route.requiredRoles) &&
      route.requiredRoles.length === 0
    )
      return true;
    if (
      userLevelAccessQuery &&
      route.requiredRoles?.some((role) => roles.includes(role))
    ) {
      if (Object.hasOwn(route, 'children')) {
        return {
          ...route,
          children: filterRoutesFromRoles(route.children, roles, userLevel),
        };
      }
      return route;
    } else {
      if (Object.hasOwn(route, 'children')) {
        return {
          ...route,
          children: filterRoutesFromRoles(route.children, roles, userLevel),
          element: <NotFound status="not-authorized" statusCode="403" />,
        };
      } else {
        return {
          ...route,
          element: <NotFound status="not-authorized" statusCode="403" />,
        };
      }
    }
  });
}

function generateSidebar(routes, roles, t, root = '', userLevel) {
  return routes
    .filter(
      (route) =>
        ((!Array.isArray(route.userAccessLevel) ||
          route.userAccessLevel.findIndex((c) => c === userLevel) > -1) &&
        Array.isArray(route.requiredRoles) &&
        route.requiredRoles.length === 0
          ? true
          : (!Array.isArray(route.userAccessLevel) ||
              route.userAccessLevel.findIndex((c) => c === userLevel) > -1) &&
            route.requiredRoles?.some((role) => roles.includes(role))) &&
        route.menuItem,
    )
    .map((route) => {
      if (route.children && route.children.some((r) => r.menuItem)) {
        return {
          // ...route,
          key: root + '/' + route.path,
          icon: route?.icon,
          label: t(route?.title),
          order: route?.order || 90,
          children: generateSidebar(
            route.children.filter((r) => r.menuItem),
            roles,
            t,
            root + '/' + route.path,
            userLevel,
          ),
        };
      } else {
        return {
          // ...route,
          key: route.path ? root + '/' + route.path : root,
          icon: route.icon,
          order: route?.order || 90,
          label: t(route.title),
        };
      }
    })
    .sort((a, b) => a.order - b.order);
}

function Landing() {
  const { user } = useUserController();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    // after login, redirect to the path that user tried to access before login
    const redirectPath = localStorage.getItem('redirect');
    const redirectSearchParams = localStorage.getItem('searchParams');
    if (redirectPath) {
      localStorage.removeItem('redirect');
      localStorage.removeItem('searchParams');
      navigate(redirectPath + (redirectSearchParams || ''));
    } else {
      const companyId = user.companyId;
      if (
        companyId === COMPANIES.MEDIAMARKT &&
        COUNTRIES.MM_TURKIYE === user?.countryId
      )
        navigate('/checklist/reports');
      else if (companyId === COMPANIES.SEPHORA)
        navigate('/checklist/dashboard');
      else if (companyId === COMPANIES.SEPHORA_FRANCE)
        navigate('/checklist/usageDashboard');
      else navigate('/checklist/dashboard');
    }
  }, []);

  return (
    <Page title="Profile">
      <UserProfilePage />
    </Page>
  );
}
