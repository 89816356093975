import { Outlet } from 'react-router-dom';
import { COMPANIES } from 'Core/API';
import Page from 'Core/components/page';

import FloorManagementHome from '@maple-tech/floor-management-web-app/HomePage';
import AnimeteurHome from '@maple-tech/animeteur-web-app/HomePage';

import ApartmentIcon from '@mui/icons-material/Apartment';

export const FloorManagement_BASE_ROUTE = {
  index: '/floor-management',
  animeteur: 'animetaur',
};

const ROUTES = {
  index: 'floor-management',
};

const subRoutesList = [
  {
    menuItem: true,
    requiredRoles: [
      'admin',
      COMPANIES.LOREM,
      COMPANIES.WORKMAPLE,
      COMPANIES.DEMO,
      COMPANIES.VINEYARD_VALLEY,
      COMPANIES.CENTRAL_RETAIL,
    ],
    title: 'Internal Employees',
    path: '',
    element: (
      <Page title={'Floor Management'} contentStyle={{ padding: 0 }}>
        <FloorManagementHome />
      </Page>
    ),
    index: true,
  },
  {
    menuItem: true,
    requiredRoles: [
      'admin',
      COMPANIES.LOREM,
      COMPANIES.WORKMAPLE,
      COMPANIES.DEMO,
      COMPANIES.VINEYARD_VALLEY,
      COMPANIES.CENTRAL_RETAIL,
    ],
    title: 'External Employees',
    path: FloorManagement_BASE_ROUTE.animeteur,
    element: (
      <Page title={'Animateurs'} contentStyle={{ padding: 0 }}>
        <AnimeteurHome />
      </Page>
    ),
  },
];

const FloorManagementPagesRoutes = {
  menuItem: true,
  requiredRoles: [
    'admin',
    COMPANIES.LOREM,
    COMPANIES.WORKMAPLE,
    COMPANIES.DEMO,
    COMPANIES.VINEYARD_VALLEY,
    COMPANIES.CENTRAL_RETAIL,
  ],
  path: ROUTES.index,
  section: true,
  order: 7,
  title: 'Floor Management',
  icon: <ApartmentIcon />,
  element: <Outlet />,
  children: subRoutesList,
};

export default FloorManagementPagesRoutes;
