import ReactMarkdown from 'react-markdown';
import React, { useLayoutEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  List,
  ListItem,
  Modal,
  Fab,
  AppBar,
  Toolbar,
  Divider,
  IconButton,
  TextareaAutosize,
  Popover,
  useTheme,
  Container,
  ListItemAvatar,
  Avatar,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import {
  AspectRatio,
  AutoAwesome,
  Camera,
  CameraEnhanceRounded,
  ClearAll,
  MicRounded,
  UploadFileRounded,
  ZoomInMap,
  ZoomOutMap,
} from '@mui/icons-material';
import OpenAI from 'openai';
import { useLocation } from 'react-router-dom';
const openai = new OpenAI({
  baseURL:
    'https://xigf46chs76v8imr.us-east-1.aws.endpoints.huggingface.cloud/v1/',
  apiKey: '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',
  dangerouslyAllowBrowser: true,
  organization: 'Maple Retail',
  project: 'Maple AI',
  // model: "tgi",
  // stream: true,
});

const systemPrompt = {
  role: 'system',
  content: `You are a manager assistant AI designed to assist managers within a retail company. Your primary role is to support managers by providing actionable insights from the Maple application and facilitating their decision-making processes. Your communication style is business professional, ensuring kindness, clarity, and consistency in every interaction.
Guidelines
Tone and Format
Respond concisely unless the user explicitly requests detailed explanations.
Present information using an executive summary format, such as:
Bullet points for lists.
Tables for comparisons.
Charts for visual insights (if the medium supports it).
Behavioral Rules
Maintain professionalism and avoid generating inappropriate or harmful content.
If unsure about a user’s intent or request, politely ask for clarification.
Never guess an answer. If uncertain or if the requested information is unavailable, politely state:
Example: "I’m sorry, but I cannot provide an accurate answer to that. Please check with the appropriate source or department."
Knowledge and Data Boundaries
Use only the data accessible within the Maple application or other approved data sources. If external information is required, state that it is beyond your scope.
Ensure all provided insights remain within the user’s permission scope:
Store Managers: Provide only information related to their specific store(s) where they have writer or reader permissions.
District Managers: Share data only for their assigned district(s), ensuring no information is disclosed about other districts or unrelated areas.
If the user requests information outside their scope, politely decline and clarify:
Example: "I’m unable to provide details for other stores or districts as it is outside your permission scope."
Data Sources and Modules
The data you utilize comes from Maple's modules, including:
Checklist: Collecting operational data from stores and assessing store performance.
Knowledge Base: A centralized repository of documentation, guides, and resources.
Survey: Feedback and performance metrics collected through surveys.
Ticket: Issue tracking and resolution support.
Structure your responses to reference these modules where applicable.
Examples of Actions
Sample Prompts and Expected Answers
Prompt: “Do we have any problematic topics for the stores in France?”
Expected Answer: "Cleaning is the most recurring issue in your area. XX% of the stores have reported this problem consistently over the last 3 months."
Prompt: “Are there any regions I should be monitoring?”
Expected Answer: "Most regions are performing well, but Bretagne is experiencing challenges with employee management and visual merchandising. It may require closer attention this week."
Prompt: “Have all regions completed their Animation checklist for the latest Animation?”
Expected Answer: "Yes, all regions have completed their Christmas Animation checklist in line with the schedule."
Prompt: “What made the biggest impact on our locations’ performance this year?”
Expected Answer: "This year, operational efficiency improved significantly in March and July, coinciding with the deployment of new Animation checklists and focused training initiatives for staff. These actions collectively led to improved compliance and higher satisfaction scores."
Prompt: “Which areas require immediate attention?”
Expected Answer: "The stores in Bretagne are underperforming compared to other locations. Additionally, Employee Management and Stock Management appear to be common issues."
Response Style
Always provide clear, actionable insights that managers can directly apply in their roles.
Avoid overloading responses with unnecessary details; focus on relevant, actionable information.

Checklist dashboard extra prompts:
General Scoring Questions
Question: "How is the performance of the country compared to last month?"
Answer: "The overall performance for France is 84%, with an increase compared to last month. Key areas like Bretagne improved slightly (+4.3%), while Ile-de-France dropped by 1.6%."
Question: "Which areas are underperforming and require immediate attention?"
Answer: "Normandie (83%) and Ile-de-France (83.7%) are slightly underperforming compared to the national average of 84%. Bretagne (79%) has the lowest performance and may need urgent attention."
Question: "What is the trend for Bretagne's performance over the last quarter?"
Answer: "Bretagne's performance shows a slight but consistent improvement over the last quarter, with a 4.3% increase in November, despite previous months of stagnation."
Question: "Can you show a comparison of performance between Flagship Stores and Concept Stores?"
Answer: "Flagship Stores scored 83.3%, while Concept Stores are slightly lower at 81.8%. Both clusters experienced a slight decline compared to previous periods."
Trends Analysis
Question: "What caused the 1.6% drop in October?"
Answer: "The 1.6% drop in October was likely due to incomplete checklist submissions and ongoing issues in Employee Management, particularly in Ile-de-France and Normandie."
Question: "Can you summarize the monthly performance trends for 2024?"
Answer: "Monthly trends show consistent improvement throughout 2024, with notable increases in February (+69.4%) and July (+6.2%). However, October showed a decline of 1.6%."
Question: "Which months saw the highest improvement, and what were the contributing factors?"
Answer: "February (+69.4%) and July (+6.2%) showed the highest improvements, driven by focused efforts on checklist completion and targeted training initiatives for staff."
Question: "How does the checklist completion rate correlate with performance trends?"
Answer: "Checklist completion rates strongly correlate with performance trends. Periods with higher checklist completion, like July, show noticeable performance increases, while lower completion in October corresponds with a performance drop."
Topics/Store-Specific Insights
Question: "Which areas are struggling with Employee Management?"
Answer: "Bretagne (73.6%) and Normandie (74.6%) show the lowest scores in Employee Management, indicating challenges in this area."
Question: "What are the top-performing stores in terms of Visual Merchandising?"
Answer: "Stores in Grand Est and Bretagne have shown strong performance in Visual Merchandising, with scores averaging above 74.8%."
Question: "Can you rank the topics based on their completion rates?"
Answer: "Topics are ranked as follows: Store Evaluation (85.6%), Visual Merchandising (74.8%), Stock Management (74.6%), and Employee Management (73.6%)."
Question: "What is the overall score for Store Evaluation, and how does it compare across regions?"
Answer: "Store Evaluation scored 85.6%, the highest among all topics. Grand Est performed best in this area, while Bretagne needs improvement."
Checklist Completion
Question: "Which checklists are incomplete for the Bretagne region?"
Answer: "Bretagne shows incomplete submissions for Employee Management and Stock Management, impacting overall performance."
Question: "What percentage of stores have completed their Animation checklist?"
Answer: "Currently, 100% of the stores have completed their Animation checklist, as per the latest updates."
Question: "Are there any stores consistently failing to complete checklists on time?"
Answer: "Normandie has shown delayed submissions for multiple checklists over the past quarter, affecting its overall performance."
Cluster-Specific Analysis
Question: "What are the top-performing clusters this quarter?"
Answer: "Corner Stores performed best, with an 82.6% score, while Flagship and Concept Stores are slightly lower at 83.3% and 81.8%, respectively."
Question: "Which clusters are facing challenges in Stock Management?"
Answer: "Concept Stores show the most significant challenges in Stock Management, with a performance score below 75%."
Question: "Can you compare the Corner Store cluster's performance to the Flagship Store cluster?"
Answer: "Corner Stores (82.6%) are slightly behind Flagship Stores (83.3%), though both clusters show room for improvement in Employee Management."
Problem Areas
Question: "What are the most recurring problems in Ile-de-France?"
Answer: "The most recurring issues in Ile-de-France relate to Employee Management and Stock Management, which are reflected in the area's lower scores."
Question: "Which regions have shown the highest improvement in handling cleaning issues?"
Answer: "Grand Est has shown the highest improvement in resolving cleaning issues, with consistent gains over the past three months."
Question: "Are there any regions with persistent issues related to Stock Management?"
Answer: "Normandie and Bretagne have persistent issues in Stock Management, with scores below the national average of 74.6%."
Usage Rates
Question: "Why is the usage rate for checklists at 0% across all months?"
Answer: "The 0% usage rate may indicate incomplete data entries in the system or a lack of proper engagement from users. This requires further investigation."
Question: "What actions are needed to improve checklist usage rates?"
Answer: "Engage regional managers to emphasize the importance of checklist completion. Training sessions and regular follow-ups may help improve engagement."
Question: "Which stores are not engaging with the system and need follow-up?"
Answer: "Stores in Normandie and Bretagne show the lowest engagement rates, with consistent 0% checklist usage recorded over the past months."

`,
};

const SpeechToText = ({
  onChange,
  onSend
}) => {
  const [transcript, setTranscript] = useState(""); // Kaydedilen metni tutacak state
  const [isListening, setIsListening] = useState(false); // Dinleme durumunu tutacak state

  // SpeechRecognition API'sini başlat
  const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = new SpeechRecognition();

  // Dil ayarı (örneğin Türkçe)
  // recognition.lang = "tr-TR";
  // united state
  recognition.lang = "en-US";

  // Sürekli dinleme için ayar
  recognition.continuous = false;

  // Konuşma başladığında çalışacak olay
  recognition.onstart = () => {
    setIsListening(true);
  };

  // Konuşma bittiğinde çalışacak olay
  recognition.onresult = (event) => {
    const transcript = event.results[0][0].transcript;
    setTranscript(transcript); // State'i güncelle
    onChange(transcript); // Dışarıya seçilen metni gönder
    setIsListening(false);
  };

  // Hata durumunda çalışacak olay
  recognition.onerror = (event) => {
    console.error("Hata:", event.error);
    setIsListening(false);
  };

  // Konuşma sona erdiğinde çalışacak olay
  recognition.onend = () => {
    setIsListening(false);
    if(transcript) {
      onSend();
    }
  };

  // Butona tıklandığında kayıt başlat/durdur
  const handleListen = () => {
    if (isListening) {
      recognition.stop(); // Dinlemeyi durdur
    } else {
      recognition.start(); // Dinlemeyi başlat
    }
  };

  return (
      <IconButton
        color={isListening ? "secondary" : "primary"}
        onClick={handleListen}
        aria-label="Microphone"
      >
        <MicRounded />
      </IconButton>

  );
};
const MapleAIAsistant = ({ disablePadding, mode = 'page' }) => {
  return;
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const theme = useTheme();
  const [isWriting, setIsWriting] = useState(false);
  const writerRef = React.useRef('');
  const [count, setCount] = useState(0);
  const [extendPaperSize, setExtendPaperSize] = useState(false);
  const customStyle =
    mode === 'page'
      ? {}
      : {
          width: extendPaperSize ? 600 : 600,
          height: extendPaperSize ? 800 : 800,
        };
  useLayoutEffect(() => {
    // Sistem prompt'unu gönder
    openai.chat.completions
      .create({
        model: 'tgi',
        messages: [systemPrompt],
        max_tokens: 2048,
        stream: false,
        parallel_tool_calls: 4,
      })
      .then((response) => {
        setMessages((prev) => [
          ...prev,
          {
            content: response.choices[0]?.delta?.content || '',
            role: 'system',
          },
        ]);
      });
  }, []);
  const handleSend = async () => {
    if (!input.trim()) return;

    // Kullanıcı mesajını ekle
    setMessages((prev) => [
      ...prev,
      { content: input, role: 'user', time: new Date().toLocaleTimeString() },
      {
        content: '',
        role: 'asistant',
        isWriting: true,
        time: new Date().toLocaleTimeString(),
      },
    ]);
    // curl "https://xigf46chs76v8imr.us-east-1.aws.endpoints.huggingface.cloud/v1/chat/completions" \

    // Hugging Face API'sine istek gönder
    try {
      setIsWriting(true);
      const stream = await openai.chat.completions.create({
        model: 'tgi',
        messages: [
          systemPrompt,
          {
            role: 'user',
            content: input,
          },
        ],
        max_tokens: 2048,
        stream: true,
      });

      let tmpMessages = '';
      for await (const chunk of stream) {
        // process.stdout.write(chunk.choices[0]?.delta?.content || '');
        // update last sender!=user message with the model response
        if (!chunk.choices[0]?.delta?.content) continue;
        tmpMessages += chunk.choices[0]?.delta?.content;
        console.log('chunk', chunk.choices[0]?.delta?.content, tmpMessages);
        writerRef.current = tmpMessages;
        console.log('writerRef.current', writerRef.current);
        setCount((f) => f + 1);
      }
      // set isWriting to false
      setMessages((prev) => {
        const lastUserMessageIndex = prev

          .map((msg, index) => (msg.role === 'asistant' ? index : null))
          .filter((index) => index !== null)
          .pop();
        if (lastUserMessageIndex !== undefined) {
          prev[lastUserMessageIndex].isWriting = false;
          prev[lastUserMessageIndex].content = tmpMessages;
        }
        return [...prev];
      });
      writerRef.current = '';
      setIsWriting(false);
      // setMessages((prev) => [...prev, { text: botMessage, sender: 'bot' }]);
    } catch (error) {
      setIsWriting(false);
      console.error('API isteği başarısız oldu:', error);
    }

    // Input'u temizle
    setInput('');
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: disablePadding ? 0 : 8,
        // border: '1px solid #ccc',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#f9f9f9',
        ...(customStyle || {}),
      }}
    >
      <Paper
        elevation={3}
        sx={{
          flex: 1,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <AppBar
          position="static"
          color="inherit"
          elevation={0}
          style={{ height: 56 }}
        >
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Maple AI
            </Typography>
            {/* Extend */}
            {/* {mode === 'fab' && (
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setExtendPaperSize((f) => !f)}
              >
                {extendPaperSize ? <ZoomInMap /> : <ZoomOutMap />}
              </IconButton>
            )} */}
            <IconButton
              edge="end"
              color="inherit"
              disabled={messages.length === 0}
              onClick={() => setMessages([])}
            >
              <ClearAll />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
        <Container
          sx={{
            height: 'calc(100% - 256px)',
            flexGrow: 1,
            overflow: 'overlay',
          }}
        >
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              padding: '10px',
              overflowY: 'auto',
              flexShrink: 1,
              // toolbar ile prompt input alanı arasındaki boşluğu ayarlar
            }}
          >
            {messages.map((msg, index) => (
              <ListItem
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent:
                    msg.role === 'user' ? 'flex-end' : 'flex-start',
                  marginBottom: '10px',
                }}
              >
                <ListItemAvatar>
                  <Avatar src="https://alpha.workmaple.com/app-icons/android-icon-72x72.png" />
                </ListItemAvatar>
                <Box
                  sx={{
                    backgroundColor:
                      msg.role === 'user' ? '#d1e7dd' : '#f8d7da',
                    padding: '8px',
                    borderRadius: '8px',
                    maxWidth: '70%',
                  }}
                >
                  <Typography variant="body1">
                    <ReactMarkdown>
                      {msg.isWriting ? writerRef.current : msg.content}
                    </ReactMarkdown>
                  </Typography>
                  <Typography variant="caption" sx={{ color: '#666' }}>
                    {msg.time}
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        </Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            border: `1px solid ${
              theme.palette.mode === 'dark' ? '#404040' : '#d4d4d4'
            }`,
            borderRadius: '8px',
            backgroundColor:
              theme.palette.mode === 'dark' ? '#171717' : '#fafafa',
            color: theme.palette.mode === 'dark' ? '#d4d4d4' : '#525252',
            maxHeight: '256px',
          }}
        >
          <Box sx={{ p: 2 }}>
            <TextField
              multiline
              rows={2}
              maxRows={10}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder='Type "Translate the following English text to French: "Hello, how are you?"'
              variant="standard"
              fullWidth
              InputProps={{
                disableUnderline: true,
                style: {
                  backgroundColor: 'transparent',
                  // color: theme.palette.mode === 'dark' ? '#d4d4d4' : '#525252',
                  padding: '8px',
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
              px: 2.5,
              py: 2,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {/* Camera */}
              <IconButton
                title="Use Camera"
                aria-label="Use Camera"
                color="inherit"
                disabled
                sx={{
                  color:
                    theme.palette.mode === 'dark' ? '#ffffff99' : '#00000099',
                }}
              >
                <CameraEnhanceRounded />
              </IconButton>
              {/* Upload Image */}
              <IconButton
                title="Upload Image"
                aria-label="Upload Image"
                color="inherit"
                disabled
                sx={{
                  color:
                    theme.palette.mode === 'dark' ? '#ffffff99' : '#00000099',
                }}
              >
                <UploadFileRounded />
              </IconButton>
              {/* Use Voice */}
              {/* <SpeechToText onChange={(text) => setInput(text)} onSend={()=>{
                handleSend();
              }} /> */}
              <IconButton
                title="Use Voice"
                aria-label="Use Voice"
                color="inherit"
                disabled
                sx={{
                  color:
                    theme.palette.mode === 'dark' ? '#ffffff99' : '#00000099',
                }}
              >
                <MicRounded />
              </IconButton>
            </Box>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSend}
              disabled={isWriting || !input.trim()}
              startIcon={<AutoAwesome />}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Paper>

      {/* <Box sx={{ display: 'flex' }}>
        
        <TextField
          fullWidth
          variant="outlined"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Mesajınızı yazın..."
          sx={{ marginRight: '10px' }}
          
        />
        <Button variant="contained" color="primary" onClick={handleSend}>
          Gönder
        </Button>
      </Box> */}
    </Box>
  );
};
export const MapleAIAsistantFab = () => {
  const [anchorEl, setAnchorEl] = useState(null); // Modal açık/kapalı durumu
  const location = useLocation();
  const handleOpen = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  console.log(
    "localStorage.getItem('mapleAiIsEnable')",
    localStorage.getItem('mapleAiIsEnable'),
  );
  return;
  
  if (localStorage.getItem('mapleAiIsEnable') !== 'true') return null;
  // eğer urlde maple-ai yazıyorsa render alma
  if (location.pathname.includes('maple-ai')) return null;
  return (
    <>
      {/* FAB Butonu */}
      <Fab
        color="primary"
        aria-label="chat"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        onClick={handleOpen}
      >
        <AutoAwesome />{' '}
        <AutoAwesome
          sx={{
            position: 'absolute',
            top: 12,
            right: 12,
            width: 12,
            height: 12,
            zIndex: 1000,
          }}
        />
      </Fab>

      {/* Modal (Popup) */}
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <MapleAIAsistant disablePadding mode="fab" />
      </Popover>
    </>
  );
};

export default MapleAIAsistant;
