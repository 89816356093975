import { Outlet } from 'react-router-dom';
import { COMPANIES } from 'Core/API';
import Page from 'Core/components/page';

import VMHome from '@maple-tech/visual-merchandising-web-app/HomePage';
import Detail from '@maple-tech/visual-merchandising-web-app/Detail';
// import Manage from '@maple-tech/visual-merchandising-web-app/Manage';

import StreamIcon from '@mui/icons-material/Stream';

export const VM_BASE_ROUTE = {
  index: '/vm',
  category: '/vm/category',
  detail: '/vm/detail/:id',
  manage: '/vm/manage',
};

const ROUTES = {
  index: 'vm',
  category: 'category',
  detail: 'detail/:id',
  manage: 'manage',
};

const subRoutesList = (user) => [
  {
    // menuItem: true,
    requiredRoles: [
      'admin',
      COMPANIES.LOREM,
      COMPANIES.WORKMAPLE,
      COMPANIES.VINEYARD_VALLEY,
      COMPANIES.CENTRAL_RETAIL,
      // ! HARD CODED VALUES
      user.id === 'B2T7XZYENNGNYXJMFHUCLFIHRA' && COMPANIES.SEPHORA_FRANCE,
      (user.id === 'LA2SXNEFT5ACO6G2YQPPWGXUGY' ||
        user.id === 'XVVEEW22WZCDE3CCN6EG4SC4W4') &&
        COMPANIES.SEPHORA,
    ].filter(Boolean),
    path: '',
    title: 'Visual Merchandising',
    element: <Detail />,
    index: true,
  },
];

const VMPagesRoutes = (user) => ({
  menuItem: true,
  requiredRoles: [
    'admin',
    COMPANIES.LOREM,
    COMPANIES.WORKMAPLE,
    COMPANIES.VINEYARD_VALLEY,
    COMPANIES.CENTRAL_RETAIL,
    // ! HARD CODED VALUES
    user.id === 'B2T7XZYENNGNYXJMFHUCLFIHRA' && COMPANIES.SEPHORA_FRANCE,
    (user.id === 'LA2SXNEFT5ACO6G2YQPPWGXUGY' ||
      user.id === 'XVVEEW22WZCDE3CCN6EG4SC4W4') &&
      COMPANIES.SEPHORA,
  ].filter(Boolean),
  path: ROUTES.index,
  order: 3,
  section: true,
  title: 'Visual Operations',
  icon: <StreamIcon />,
  element: (
    <Page title="Visual Operations" contentClassName="!p-0">
      <Outlet />
    </Page>
  ),
  children: subRoutesList(user),
});

export default VMPagesRoutes;
